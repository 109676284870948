<template>
  <PflegeHero
    v-editable="props.blok"
    :data-uid="props.blok._uid"
    :heading="props.blok.heading"
    :subline="props.blok.subline"
    :sublineTag="props.blok.sublineTag"
    :textAlign="props.blok.alignment"
    headingTag="h1"
  >
    <template v-if="props.blok.media" #figure>
      <WPElementMedia
        v-for="asset in props.blok.media"
        :key="asset._uid"
        :blok="asset"
        :aspectRatio="{
          desktop: aspectRatios.xs,
          tablet: aspectRatios.s,
          mobile: aspectRatios.l
        }"
      />
    </template>
    <template v-if="props.blok.quickFacts && props.blok.quickFacts.length > 0" #quickFacts>
      <li v-for="item in props.blok.quickFacts" :key="item._uid" v-editable="item">
        <PflegeIcon :name="props.blok.quickFactsIcon" :color="props.blok.quickFactsIconColor" />
        <span>{{ item.label }}</span>
      </li>
    </template>
    <template v-if="props.blok.actions && props.blok.actions.length > 0" #actions>
      <WPElementButton v-for="button in props.blok.actions" :key="button._uid" :blok="button" />
    </template>
    <template v-if="willRender(props.blok.content)" #default>
      <RichtextResolver :blok="props.blok.content" />
    </template>
  </PflegeHero>
</template>

<script setup>
import { PflegeHero, PflegeIcon } from '@shared'

const props = defineProps({
  blok: {
    type: Object,
    required: true
  }
})
</script>
